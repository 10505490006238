import React, { useCallback, useEffect, useState, useRef } from 'react'
import uuid4 from "uuid4";
import { RWebShare } from "react-web-share";
import dhakImg from '../assets/dhak.png'
import Dhak from './Dhak';
import bg from '../assets/bg.jpg'
import logo from '../assets/logo.png'
import playBtn from '../assets/play.png'
import pauseBtn from '../assets/pause.png'
import restartBtn from '../assets/restart.png'
import borderImg from '../assets/border.png'
import scoreBoardImg from '../assets/score-board.png'
import resultBoardImg from '../assets/result-board.png'
import shareBtn from '../assets/share.png'
// import { saveAs } from 'file-saver';
import { toPng } from 'html-to-image';

const generateComb = () => {
    const arr = [0,0,0,0]
    const index = Math.floor(Math.random() * 4);
    arr[index] = 1
    return arr
}





const GameCanvas = () => {
    let loop = useRef(0)
    let totalLoop = useRef(0)
    let mainIndex = useRef(1)
    let frame = useRef(2)
    const shareElementRef = useRef(null);
    
    const [lines, setLines] = useState([])
    const [top, setTop] = useState(-112)
    const [speed, setSpeed] = useState(10)
    const [gameState, setGameState] = useState(0)
    const [score, setScore] = useState(0)
    const [shareUrl, setShareUrl] = useState('https://dhak.brandwind.in/')


    

    const moveElement = useCallback(() => {
        if(loop.current >= 112/frame.current){
            setLines(prev => {
                const newPrev = [...prev]
                newPrev.unshift({
                    id: uuid4(),
                    index: mainIndex.current,
                    comb: generateComb()
                })
                return newPrev
            })
            loop.current = 0
            mainIndex.current = mainIndex.current+1          
        }
        setTop(prev => {
            const newPrev = prev + frame.current
            return newPrev
        })
        loop.current = loop.current + 1
        totalLoop.current = totalLoop.current+1
        if(totalLoop.current % 500 === 0){
            setSpeed(prev => {
                if(prev > 1){
                    return prev - 1
                }
                return prev
            })      
        }
        if(totalLoop.current % 5000 === 0){
            // console.log(frame)
            if(frame.current < 6){
                frame.current = frame.current + 1
            }
        }
    }, [])

    useEffect(() => {
        // console.log(gameState)
        // if(gameState === 3){
        //     (async () => {
        //         await captureScreenshot()
        //     })()
        // }
        if(gameState !== 1){
            return
        }
        const handler = setInterval(() => {
            requestAnimationFrame(moveElement)
        }, speed)
        
        return () => clearInterval(handler)
    }, [speed, gameState])

    const onDrum = (index) => {
        setLines(prev => {
            const newPrev = [...prev]
            newPrev.splice(index, 1)
            return newPrev
        })
        if(gameState === 1){
            setScore(prev => prev + 10)
        }
    }

    const cancTop = (v, t) => {
        return (112*-v + t)
    }

    const onStart = () => {
        setLines([])
        setScore(0)
        mainIndex.current = 1
        frame.current = 2
        totalLoop.current = 0
        loop.current = 0
        setSpeed(10)
        setTop(-112)
        setGameState(1)
    }

    // const captureScreenshot = async () => {
        
        
        
    // }

    const htmlToImageConvert = async () => {
        // toPng(shareElementRef.current, { cacheBust: false })
        //   .then(async (dataUrl) => {
        //     // console.log(dataUrl)
        //     // const link = document.createElement("a");
        //     // link.download = "my-image-name.png";
        //     // link.href = dataUrl;
        //     // link.click()
        //     // console.log(link)
        //     // const blob = new Blob([dataUrl], { type: 'image/png' });
        //     const imageBlob = await fetch(dataUrl).then((response) => response.blob());
        //     // const imageUrl = URL.createObjectURL(imageBlob);

        //     await navigator.share({
        //         title: 'Dhak-Ki-Chiki',
        //         text: `ঢাক পিটিয়ে, আমার স্কোর ${score}! \n দেখাও তোমার ঢাকের জোর! \n শেয়ার করুন!`,
        //         // files: [new File([imageBlob], 'share.png', { type: 'image/png' })],
        //         url: 'http://dhak.brandwind.in/'
        //     });
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        await navigator.share({
            title: 'Dhak-Ki-Chiki',
            text: `ঢাক পিটিয়ে, আমার স্কোর ${score}! \n দেখাও তোমার ঢাকের জোর! \n শেয়ার করুন!`,
            // files: [new File([imageBlob], 'share.png', { type: 'image/png' })],
            url: 'http://dhak.brandwind.in/'
        });
      };
    
  return (
    <div className={`h-screen bg-cover bg-center bg-no-repeat`} style={{backgroundImage: `url(${bg})`}}>
        {gameState >= 1 ? 
        <div className='h-screen overflow-hidden w-full max-w-[700px] mx-auto relative'>
            <div className='h-screen overflow-hidden flex justify-evenly'>
                <img src={borderImg} alt="" />
                <img src={borderImg} alt="" />
                <img src={borderImg} alt="" />
            </div>
            <div className='flex items-center gap-2 absolute top-0 left-0 z-[500] px-2 w-full'>
                <div className='relative flex-shrink-0'>
                    <img src={scoreBoardImg} className='h-12' alt="" />
                    <p className='text-[#F4B331] text-2xl font-bold text-right absolute top-2 left-0 w-full pr-4'>{score}</p>
                </div>
                <div className='w-full'></div>
                {gameState === 1 ? <button className='flex-shrink-0' onClick={() => setGameState(2)}>
                    <img src={pauseBtn} className='h-10' alt="" />
                </button> : null}
                {gameState === 2 ? <button className='flex-shrink-0' onClick={() => setGameState(1)}>
                    <img src={playBtn} className='h-10' alt="" />
                </button> : null}
                <button className='flex-shrink-0' onClick={onStart}>
                    <img src={restartBtn} alt="" className='h-10' />
                </button>
            </div>
            {lines.map((item, i) => (
                <div className={`flex w-full absolute top-0 left-0`} style={{top: cancTop(item.index, top) + 'px'}} key={item.id}>
                    <Dhak show={item.comb[0] === 1} img={dhakImg} onFinger={() => onDrum(i)} setGameState={setGameState} gameState={gameState} top={top} />
                    <Dhak show={item.comb[1] === 1} img={dhakImg} onFinger={() => onDrum(i)} setGameState={setGameState} gameState={gameState} top={top} />
                    <Dhak show={item.comb[2] === 1} img={dhakImg} onFinger={() => onDrum(i)} setGameState={setGameState} gameState={gameState} top={top} />
                    <Dhak show={item.comb[3] === 1} img={dhakImg} onFinger={() => onDrum(i)} setGameState={setGameState} gameState={gameState} top={top} />
                </div>
            ))}
        </div>
        : null}
        {gameState === 0 ? 
        <div className='h-screen overflow-hidden w-full max-w-[700px] mx-auto flex pt-24 justify-center px-4'>
            <div>
                <div className='mb-12'>
                    <img src={logo} className='w-full max-w-[234px]' alt="" />
                </div>
                <div className='flex justify-center'>
                    <button onClick={onStart}>
                        <img src={playBtn} className='w-16' alt="" />
                    </button>
                </div>
            </div>
        </div>
        : null}
        {
            gameState === 3 ? 
            <div className='h-screen bg-[#CB0101] fixed w-screen z-[501] left-0 top-0 bg-opacity-80 px-4 flex justify-center pt-32 overflow-hidden'>
                <div className='w-full max-w-[320px] relative h-fit'>
                    <img src={resultBoardImg} className='w-full mx-auto' alt="" />
                    <p className='text-[#F4B331] text-2xl font-bold text-right absolute top-[164px] left-0 pr-14 w-full'>{score}</p>
                    <div className='w-full absolute -bottom-4 flex justify-center gap-8'>
                        <button className='flex-shrink-0' onClick={onStart}>
                            <img src={restartBtn} alt="" className='h-12' />
                        </button>
                        {/* <RWebShare data={{
                            text: `ঢাক পিটিয়ে, আমার স্কোর ${score}! \n দেখাও তোমার ঢাকের জোর! \n শেয়ার করুন!`,
                            url: shareUrl,
                            title: "Dhak-Ki-Chiki",
                        }}> */}
                        <button className='flex-shrink-0' onClick={htmlToImageConvert}>
                            <img src={shareBtn} alt="" className='h-12' />
                        </button>
                        {/* </RWebShare> */}
                    </div>
                </div>



               <div className='absolute top-[-4000px]'>
                <div className='w-full max-w-[320px] relative h-fit' ref={shareElementRef}>
                    <img src={resultBoardImg} className='w-full mx-auto' alt="" />
                    <p className='text-[#F4B331] text-2xl font-bold text-right absolute top-[164px] left-0 pr-14 w-full'>{score}</p>
                </div>
               </div>
            </div>
            : null
        }
    </div>
  )
}

export default GameCanvas