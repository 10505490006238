import GameCanvas from "./Components/GameCanvas";

function App() {
  return (
    <div className="App">
        <GameCanvas />
    </div>
  );
}

export default App;
