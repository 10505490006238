import React, { useEffect, useRef, useState } from 'react'


const Dhak = ({img, show, onFinger, setGameState, top, gameState}) => {

    const [audio, setAudio] = useState(null)
    const thisRef = useRef(null)

    const play = () => {
        if(show && gameState === 1){
            audio.play()
            onFinger()
        }
        
    }
    useEffect(() => {
        setAudio(new Audio(require(`../assets/5.mp3`)))
    }, [])

    useEffect(() => {
        if (thisRef.current && show) {
            const rect = thisRef.current.getBoundingClientRect();
            const absoluteTop = rect.top + window.scrollY;
            
            if(absoluteTop > window.innerHeight){
                setGameState(3)
            }
        }
    }, [top])

  return (
        <div className='w-full h-28' onTouchStart={play} ref={thisRef}>
            {show ?
                <img src={img} className='h-24 mx-auto' alt="" /> 
            : null}
        </div>
  )
}

export default Dhak